import bootstrap from "../../imgs/bootstrap.png"
import bussinesCase from "../../imgs/bussinesCase.png"
import crystalReports from "../../imgs/crystalreports.png"
import css from "../../imgs/css.png"
import escom from "../../imgs/escom.png"
import fb from "../../imgs/fb.png"
import fca from "../../imgs/fca.jpg"
import fcc from "../../imgs/fcc.svg"
import fccSchool from "../../imgs/fcc-school.png"
import git from "../../imgs/git.png"
import github from "../../imgs/github.png"
import html from "../../imgs/html.png"
import lIn from "../../imgs/in.png"
import ipn from "../../imgs/ipn.png"
import js from "../../imgs/js.png"
import mysql from "../../imgs/mysql.png"
import node from "../../imgs/node.png"
import php from "../../imgs/php.png"
import pp1 from "../../imgs/pp01.jpg"
import pp2 from "../../imgs/pp-02.jpg"
import reactImage from "../../imgs/react.png"
import reactBootstrap from "../../imgs/reactBootstrap.png"
import scjn from "../../imgs/scjn.png"
import sistran from "../../imgs/sistran.png"
import sqls from "../../imgs/sqlserver.png"
import unam from "../../imgs/unam.png"
import vb from "../../imgs/vb.png"
import vbNet from "../../imgs/vbnet.png"
import wa from "../../imgs/wa.png"
import web from "../../imgs/web.png"

const imgs = {
    bootstrap,
    bussinesCase,
    crystalReports,
    css,
    escom,
    fb,
    fca,
    fcc,
    fccSchool,
    git,
    github,
    html,
    lIn,
    ipn,
    js,
    mysql,
    node,
    php,
    pp1,
    pp2,
    reactImage,
    reactBootstrap,
    scjn,
    sistran,
    sqls,
    unam,
    vb,
    vbNet,
    wa,
    web
}

export default imgs; 
