
import './App.css';
//import PageContainer from './Components/General/PageContainer';
import CV from './CV';

function App() {
  return (
    <CV />
  );
}

export default App;
