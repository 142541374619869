import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import imgs from "./Components/General/imgs"
import { faEnvelope, faPhoneSquare, faSquareEnvelope, faSquarePhone, faSuitcase, faUser } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"

export default function CV(){

    const {pp2,html,css,js,reactImage,bootstrap,reactBootstrap,node,mysql,php,scjn,bussinesCase,sistran,fcc,unam,ipn,lIn,wa,github,web} = imgs
    const [english,setEnglish] = useState(false)
    const changeSwitch = ()=>setEnglish(!english)

    return (<div className="main-container">
        <div className="switch-container">
            {english?'Español':'English'}&nbsp;
            <label class="switch">
                <input type="checkbox" onChange={changeSwitch} />
                <span class="slider round"></span>
            </label>
        </div>
        <header className="bordered box-shadow-header box-background">
            <div className="img-container">
                <div className="image-radius">
                    <img src={pp2} alt="José Antonio Amaya Hernández" />
                </div>
            </div>
            <div className="general-info-container">
                <div className="name">José Antonio Amaya Hernández</div>
                <div className="position">{english?'Front End Developer Jr':'Desarrollador Front End Junior'}</div>
                <div className="telephone"><FontAwesomeIcon icon={faSquarePhone} /> 55 5176 2009</div>
                <div className="email"><FontAwesomeIcon icon={faSquareEnvelope} /> <a href="mailto:antonio.amaya@antonioamaya.com.mx">antonio.amaya@antonioamaya.com.mx</a></div>
                <div className="skills-container">
                    <div className="skill"><img src={html} alt="HTML" /></div>
                    <div className="skill"><img src={css} alt="CSS" /></div>
                    <div className="skill"><img src={js} alt="Java Script" /></div>
                    <div className="skill"><img src={reactImage} alt="React" /></div>
                    <div className="skill"><img src={bootstrap} alt="Bootstrap" /></div>
                    <div className="skill"><img src={reactBootstrap} alt="React Bootstrap" /></div>
                    <div className="skill"><img src={node} alt="Node" /></div>
                    <div className="skill"><img src={mysql} alt="MySQL" /></div>
                    <div className="skill"><img src={php} alt="PHP" /></div>
                </div>
            </div>
        </header>
        <main>
            <div className="overview bordered box-background box-shadow-header">
                <h3>{english?'Sumary':'Resumen'}</h3>
                {
                    english
                    ?'Just graduated of Computer Science career, with previous experience in Software Development and Web Technologies. I am looking for an opportunity to professional grow up as a Full Stack Developer. '
                    :'Recién egresado de la carrera de Informática, con experiencia previa en el Desarrollo de Software y Páginas Web. Busco desarrollar mi carrera profesional en el ámbito del desarrollo de entornos web como FullStack Developer.'
                }<br /><br />
                {
                    english
                    ?'I well know the basic structure of web and I have developed some scalable apps who resolves the principal automatization issues of the intern processes in the transparency area of the Suprema Corte de Justicia de la Nación.'
                    :'Actualmente conozco bien las tecnologías básicas que componen la web y he desarrollado un par de aplicaciones escalables para solventar algunas necesidades de automatización de procesos internos para la Suprema Corte De Justicia de la Nación.'
                }<br /><br />
                {
                    english
                    ?'I aspire to improve my skills as a Front End Developer and venture into the Back End Development, with the finality of finding a solid Stack that allows me to become a great Full Stack Developer.'
                    :'Aspiró mejorar mis habilidades como Front-End e incursionar en el Back-End en búsqueda de un Stack sólido que me permita consumarme como un Fullstack Developer completo.'
                }
            </div>
            <div className="education bordered box-background box-shadow-header">
                <h3>{english?'Experience':'Experiencia Laboral'}</h3>
                <div className="work-container bordered">
                    <div className="work-header">
                        <div className="work-img">
                            <img src={scjn} alt="Suprema Corte de Justicia de la Nación" />
                        </div>
                        <div className="work-name"><h4>Suprema Corte de Justicia de la Nación</h4></div>
                        <div className="work-time">2023</div>
                    </div>
                    <div className="work-position"><h4>{english?'Intern Developer':'Prestador de Servicio Social'}</h4></div>
                    <div className="work-description">
                        <div className="boss-container">
                            <div className="boss-title">
                                <div className="boss-name"><FontAwesomeIcon icon={faUser} /> Gustavo Martinéz Peña</div>
                                <div className="boss-position"><FontAwesomeIcon icon={faSuitcase} />{english?'Vice Principal of Information Publication':'Subdirector de Publicación de Información'}</div>
                            </div>
                            <div className="boss-contact">
                                <div className="boss-phone"><FontAwesomeIcon icon={faPhoneSquare} /> 5377-3000 Ext1. 2868</div>
                                <div className="boss-email"><FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:gmpena@mail.scjn.gob.mx">gmpena@mail.scjn.gob.mx</a></div>
                            </div>
                        </div>
                        {
                            english
                            ?'I helped with the maintenance of the Transparency Web Platform of the Suprema Corte de Justicia de la Nación, and I developed some intern apps. '
                            :'Apoyo en el mantenimiento de la plataforma de Transparencia de la Suprema Corte de Justicia de la Nación, desarrollo de aplicaciones internas.'
                        }
                        
                    </div>
                </div>
                <div className="work-container bordered">
                    <div className="work-header">
                        <div className="work-img">
                            <img src={bussinesCase} alt="Certifac" />
                        </div>
                        <div className="work-name"><h4>Certifac</h4></div>
                        <div className="work-time">2015</div>
                    </div>
                    <div className="work-position"><h4>{english?'Help Desk Assistant / VB.Net Developer ':'Técnico Help Desk / Desarrollador VB.Net'}</h4></div>
                    <div className="work-description">
                        {
                            english
                            ?'I worked as Help Desk Assistant, Customer Service Agent and I helped with the maintenance of the version 2 of web platform and development of the version 3'
                            :'Atención al cliente y soporte de Help Desk interno, mantenimiento de la plataforma 2.0, desarrollo de plataforma 3.0'
                        }
                        
                    </div>
                </div>
                <div className="work-container bordered">
                    <div className="work-header">
                        <div className="work-img">
                            <img src={sistran} alt="Sistran de México" />
                        </div>
                        <div className="work-name"><h4>Sistran de México</h4></div>
                        <div className="work-time">2012</div>
                    </div>
                    <div className="work-position"><h4>{english?'Visual Basic Developer':'Desarrollador Visual Basic'}</h4></div>
                    <div className="work-description">
                        {
                            english
                            ?'I have been part of the development, management and maintenance of the app and Help Desk Assistance to the end users'
                            :'Desarrollo, gestión y mantenimiento de la aplicación, soporte técnico para usuarios finales'
                        }
                    </div>
                </div>
            </div>
            <div className="experience bordered box-background box-shadow-header">
                <h3>{english?'Education':'Educación'}</h3>
                <div className="school-container">
                    <div className="school-img-container">
                        <div className="school-img">
                            <img src={fcc} alt="Free Code Camp" />
                        </div>
                    </div>
                    <div className="school-information">
                        <div className="school-name">Free Code Camp</div>
                        <div className="school-subname">FullStack Development</div>
                        <div className="carrer"><a href="https://www.freecodecamp.org/StewieMayer" target="_blank" rel="noopener noreferrer">www.freecodecamp.org/StewieMayer</a></div>
                    </div>
                </div>
                <div className="school-container">
                    <div className="school-img-container">
                        <div className="school-img">
                            <img src={unam} alt="Universidad Autonoma de México" />
                        </div>
                    </div>
                    <div className="school-information">
                        <div className="school-name">Universidad Autónoma de México</div>
                        <div className="school-subname">Facultad de Contaduría y Administración</div>
                        <div className="carrer">
                            <div className="carrer-name">{english?'Computer Science Bachelors':'Licenciatura en Informática'}</div>
                            <div className="carrer-end">2022</div>
                        </div>
                    </div>
                </div>
                <div className="school-container">
                    <div className="school-img-container">
                        <div className="school-img">
                            <img src={ipn} alt="Instituto Politécnico Nacional" />
                        </div>
                    </div>
                    <div className="school-information">
                        <div className="school-name">Instituto Politécnico Nacional</div>
                        <div className="school-subname">Escuela Superior de Computo</div>
                        <div className="carrer">
                            <div className="carrer-name">{english?'Computer Science Enginering':'Ingenieria en Sistemas Computacionales'}</div>
                            <div className="carrer-end">2015</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="skills-overview bordered box-background box-shadow-header">
                <h3>{english?'Knowledge':'Conocimientos'}</h3>
                {
                    english
                    ?<>My well know technologies includes HTML, CSS, JS PHP, MySQL, React and VBA, 
                    I also know the basics of Java Servelets, Redux, SQL, Apache, IIS, MVC and SCRUM 
                    and I have a bit knoledge about of HTTP, SOAP, and REST. 
                    <a href="https://www.efset.org/cert/a89YKs" target="_blank" rel="noopener noreferrer"> English level: C1.</a></>
                    :<>Entre mis conocimientos técnicos más afianzados se encuentran lenguajes como HTML, CSS, JS, PHP, MySQL, React y VBA; 
                    en cuanto a lenguajes con los que me siento familiarizado, también he utilizado Java, Servelets, Express, Redux y SQL. 
                    Conozco el funcionamiento de tecnologías como Apache e IIS, 
                    tengo nociones en protocolos de HTTP, SOAP y REST así como con el desarrollo MVC y Scrum. 
                    <a href="https://www.efset.org/cert/a89YKs" target="_blank" rel="noopener noreferrer"> Nivel de Ingles C1</a></>
                }
            </div>
        </main>
        <footer className="bordered box-background box-shadow-header">
            <h3>{english?'Contact':'Contacto'}</h3>
            <div className="contact-container">
                <div className="contact-item">
                    <div className="contact-item-img-container">
                        <div className="contact-img">
                            <img src={lIn} alt="LinkedIn" />
                        </div>
                    </div>
                    <div className="contact-item-name">
                        <a href="https://www.linkedin.com/in/antonioamayastc/" target="_blank" rel="noopener noreferrer">/antonioamayastc</a>
                    </div>
                </div>
                <div className="contact-item">
                    <div className="contact-item-img-container">
                        <div className="contact-img">
                            <img src={wa} alt="Whatsapp" />
                        </div>
                    </div>
                    <div className="contact-item-name">
                        <a href="https://wa.me/5551762009" target="_blank" rel="noopener noreferrer">55 5176-2009</a>
                    </div>
                </div>
                <div className="contact-item">
                    <div className="contact-item-img-container">
                        <div className="contact-img">
                            <img src={github} alt="GitHub" />
                        </div>
                    </div>
                    <div className="contact-item-name">
                        <a href="https://github.com/StewieMayer" target="_blank" rel="noopener noreferrer">/StewieMayer</a>
                    </div>
                </div>
                <div className="contact-item">
                    <div className="contact-item-img-container">
                        <div className="contact-img">
                            <img src={web} alt="Antonio Amaya" />
                        </div>
                    </div>
                    <div className="contact-item-name"><a href="https://antonioamaya.com.mx/" target="_blank" rel="noopener noreferrer">
                        antonioamaya.com.mx
                    </a></div>
                </div>
            </div>
        </footer>
    </div>)

}